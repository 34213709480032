<template>
  <div class="take-attendance-page">
    <v-row justify="center">
      <v-col md="6">
        <div class="mx-4 mt-2">
          <h3 class="mb-2">
            {{ subject ?  `${subject.name} | ` : ''}}{{ room ? room.sections : 'Extra Subject' }} ({{
              moment(attendanceDate).format("ll")
            }})
          </h3>
          <div>
            <v-progress-linear
              background-color="primary lighten-4"
              color="primary"
              :value="attendancePercentage"
            ></v-progress-linear>
            <v-row no-gutters justify="space-between">
              <p class="ma-0 text-caption">Total Attendance</p>
              <p class="ma-0 text-caption">
                {{ presenteeCount }}/{{ students.length }}
              </p>
            </v-row>
          </div>
        </div>
        <v-text-field
        class="mx-4 my-2"
          hide-details="auto"
          prepend-inner-icon="mdi-magnify"
          solo
          v-model="search"
          label="Search"
          clearable
        ></v-text-field>
        <v-list v-if="!isLoading">
          <!-- <v-list-item
            class="non-focused"
          >
            <v-list-item-content> -->
              <!-- <small class="text-disabled">Student</small> -->
            <!-- </v-list-item-content>
            <v-list-item-action>
              <small class="grey--text">Picked</small>
            </v-list-item-action>
          </v-list-item> -->
          <v-list-item
            v-for="student in students.filter(s => !search || s.full_name.toLowerCase().includes(search.toLowerCase()))"
            :key="student.id"
            @click="student.checked = !student.checked"
            class="non-focused"
          >
            <v-list-item-avatar>
              <v-img :src="student.image"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ student.full_name }}</v-list-item-title>
              <v-list-item-subtitle>
                {{ student.username }}
                ({{ student.room.sections }})
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-layout>
                <rounded-checkbox
                  class="mr-2"
                  :value="student.checked"
                  @valueChanged="(v) => (student.checked = v)"
                />
                <rounded-checkbox
                  :value="!student.checked"
                  @valueChanged="(v) => (student.checked = !v)"
                  icon="mdi-close"
                  color="red"
                  class="mr-2"
                />
                <!-- <rounded-checkbox
                  :value="student.picked_up"
                  @valueChanged="(v) => (student.picked_up = v)"
                  color="blue"
                /> -->
              </v-layout>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <div v-else>
          <v-skeleton-loader
            type="list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line"
          ></v-skeleton-loader>
        </div>
      </v-col>
    </v-row>
    <v-footer app fixed class="justify-center py-2 ma-auto" elevation="10">
      <v-row justify="center">
        <v-col md="6">
          <v-row no-gutters>
            <v-col cols="6" class="text-center px-1">
              <v-btn
                :disabled="isLoading"
                @click="showMarkAllOptions = true"
                color="white"
                block
                >Mark All</v-btn
              >
            </v-col>
            <v-col cols="6" class="text-center px-1">
              <v-btn
                @click="showSubmissionConfirmationDialog = true"
                :disabled="isLoading"
                color="primary"
                block
                >Submit</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-footer>
    <intract-list-sheet
      :visible="showMarkAllOptions"
      :list="markAllOptions"
      @close="showMarkAllOptions = false"
    />
    <confirmation-dialog
      title="Are you sure you want to submit this attendance record?"
      :visible="showSubmissionConfirmationDialog"
      @successCallback="submitAttendance"
      @failureCallback="showSubmissionConfirmationDialog = false"
    />
  </div>
</template>
<script>
import Mixins from "@utils/mixins";
import RoundedCheckbox from "@components/input/RoundedCheckbox";
import IntractListSheet from "@components/generics/IntractListSheet";
import moment from "moment";
import EventBus from "@utils/event_bus";
export default {
  name: "TakeAttendance",
  mixins: [Mixins.essentials, Mixins.pullToRefresh],
  components: {
    IntractListSheet,
    RoundedCheckbox,
  },
  props: {
    subject: {
      type: Object,
      default: () => null,
    },
    daily: {
      type: Boolean,
      default: false,
    },
    room: {
      type: Object,
      default: () => null,
    },
    attendanceDate: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      moment,
      search: '',
      students: [],
      attendanceId: this.$route.params.attendanceId,
      showMarkAllOptions: false,
      showSubmissionConfirmationDialog: false,
      attendance: {
        // id: null,
        subject: null,
        room: null,
        institution: null,
        attendees: [],
        date: null,
        faculty: null,
        total: 0,
      },
      markAllOptions: [
        {
          icon: "mdi-check",
          title: "Mark all as 'Present'",
          action: () => this.markAllPresent(),
        },
        {
          icon: "mdi-close",
          title: "Mark all as 'Absent'",
          action: () => this.markAllAbsent(),
        },
      ],
    };
  },
  computed: {
    studentsEndpoint() {
      return this.Helper.addUrlParams(this.endpoints.studentViewSet, [
        ...this.room ? ["room=" + this.room.id] : ["extra_subjects="+ this.subject.id],
        "paginate=false",
        "registration_approved=true",
        "disabled=false"
      ]);
    },
    attendanceObjectLoadedIfEditing() {
      return (
        !this.attendanceId || (this.attendanceId && this.attendance.id != null)
      );
    },
    shouldProceed() {
      return (
        !this.currentUser.is_student &&
        (this.daily || this.subject) &&
        (this.room || this.subject.eligible_rooms) &&
        this.attendanceDate
      );
    },
    presenteeCount() {
      return this.students.filter((s) => s.checked).length;
    },
    attendancePercentage() {
      return (this.presenteeCount * 100) / this.students.length;
    },
  },
  methods: {
    async getStudentsForAttendance() {
      this.students = await this.api.call(
        this.essentials,
        this.studentsEndpoint
      );
      this.afterStudentsLoaded();
    },
    async getAttendanceObject() {
      var url = null
      if(this.daily)
        url = this.endpoints.classroomAttendanceViewSet + this.attendanceId + "/";
      else
        url = this.endpoints.attendanceViewSet + this.attendanceId + "/";
      this.attendance = await this.api.call(this.essentials, url);
    },

    async afterStudentsLoaded() {
      console.log(this.attendanceId);
      if (this.attendanceId) this.checkStudentsFromAttendeesAndPickedUpWhileEditing();
      else this.markAllPresent();
    },
    async markAllPresent() {
      var self = this;
      this.students.map((s) => {
        self.$set(s, "checked", true);
      });
    },
    async markAllAbsent() {
      var self = this;
      this.students.map((s) => {
        self.$set(s, "checked", false);
      });
    },
    // checks the students who were present earlier and marks other as absent
    async checkStudentsFromAttendeesAndPickedUpWhileEditing() {
      // set students checked according to attendance object
      var self = this;
      this.students.map((s) => {
        var present = self.attendance.attendees.includes(s.id);
        var picked_up = self.attendance.picked_ups.includes(s.id); 
        if (present) self.$set(s, "checked", true);
        else self.$set(s, "checked", false);
        if (picked_up) self.$set(s, "picked_up", true);
        else self.$set(s, "picked_up", false);
      });
    },

    async submitAttendance() {
      this.showSubmissionConfirmationDialog = false;
      // get a list of present student ids
      this.attendance.attendees = this.students
        .filter((s) => s.checked)
        .map((s) => s.id);
      this.attendance.picked_ups = this.students.filter(s => s.picked_up).map(s => s.id)
      this.attendance.total = this.students.length;
      // send to backend
      var url = this.endpoints.attendanceViewSet;
      if(this.daily){
        url = this.endpoints.classroomAttendanceViewSet;
      }

      if (this.attendanceId) url += this.attendanceId + "/";
      var response = await this.api.call(
        this.essentials,
        url,
        this.attendanceId ? this.api.Methods.PUT : this.api.Methods.POST,
        this.attendance
      );
      console.log(response);
      if (response) {
        this.showSnackbar({
          title: "Attendance successfully recorded!",
          type: "success",
        });
        EventBus.$emit("attendance__update_attendance_object", response);
        this.$router.back();
      }
      else{
        if(this.daily){
            this.showSnackbar({
            title: "Attendance for this day already exists",
            type: "error",
          });
        }
      }
    },
  },

  async created() {
    if (!this.shouldProceed) this.$router.replace({ name: "Attendance" });
    if (this.attendanceId) {
      this.attendance.id = this.attendanceId;
      await this.getAttendanceObject();
    }
    this.attendance.institution = this.currentInstitution.id;
    this.attendance.date = moment(this.attendanceDate).format('YYYY-MM-DD');
    this.attendance.room = this.room?.id;
    this.attendance.creator = this.currentUser.id;
    this.attendance.faculty = this.currentUser.id;
    if(!this.daily){
      this.attendance.subject = this.subject.id;
      this.attendance.date = moment(this.attendanceDate).format();
    }
    
    this.getStudentsForAttendance();
  },
};
</script>